import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topbar2',
  templateUrl: './app.topbar2.component.html'
})
export class AppTopBarComponent2 {
  user: any;
  name: string;
  type: string;
  constructor(private router: Router, private authService: AuthService) {
    if (!localStorage.getItem('user')) {
      this.authService.logOut();
    }
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user.user['user_type'] == 'root') {
      this.name = this.user.user['username'];
      this.type = 'Admin';
    }
    else if (this.user.user['user_type'] == 'distribution') {
      this.name = this.user.distribution['name'];
      this.type = 'Distribution';
    }
    else if (this.user.user['user_type'] == 'sole') {
      this.name = this.user.sole['name'];
      this.type = 'Sole';
    }
    else if (this.user.user['user_type'] == 'factory') {
      this.name = this.user.factory['name'];
      this.type = 'Factory';
    }
    else if (this.user.user['user_type'] == 'employee') {
      this.name = this.user.user['username'];
      this.type = 'Employee';
    }
  }
  onLogout() {
    this.authService.logOut();
    this.router.navigateByUrl('/login');
  }
  onExitGodMode() {
    this.changeTheme('green');
    this.router.navigateByUrl('/');

  }
  changeTheme(theme: string) {
    const layoutLink: HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement;
    layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
    const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
    themeLink.href = 'assets/theme/' + 'theme-' + theme + '.css';
  }
}
