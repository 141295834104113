import { ErrorHandler, NgModule } from "@angular/core";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JwtModule } from "@auth0/angular-jwt";

import { SharedModule } from "./shared/shared.module";
import { CoreModule } from "./shared/core/core.module";
import { FeatureModule } from "./shared/feature/feature.module";

import { AppComponent } from "./app.component";
import { AppNotfoundComponent } from "./pages/app.notfound.component";
import { AppErrorComponent } from "./pages/app.error.component";
import { AppAccessdeniedComponent } from "./pages/app.accessdenied.component";
import { AppLoginComponent } from "./pages/app.login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AppPasswordResetComponent } from "./pages/app.pass-reset.component";

import { AppRoutes } from "./app.routes";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { DayCloseDetailsReportComponent } from "./orders/reports/day-close-report-details/day-close-report-details.component";
import { NgxPrintModule } from "ngx-print";
import { GlobalErrorHandler } from './global-error-handler';
import { AgmCoreModule } from '@agm/core';
import { icon, Marker } from 'leaflet';



@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutes,
    HttpClientModule,
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter
      }
    }),

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDCS8YEQFRKRNvHGVC7wEuge2ZuGO3PSnk'
    }),
    SharedModule,
    CoreModule,
    FeatureModule,
    NgxPrintModule
  ],
  declarations: [
    AppComponent,
    AppNotfoundComponent,
    AppErrorComponent,
    AppAccessdeniedComponent,
    AppLoginComponent,
    DashboardComponent,
    AppPasswordResetComponent,
    ForgotPasswordComponent,
    DayCloseDetailsReportComponent,



  ],

  bootstrap: [AppComponent],
  providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler }]
})
export class AppModule { }
export function jwtTokenGetter() {
  return localStorage.getItem("access_token");
}
