import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { MessageService } from 'primeng/api';
import { DashboardService } from '../services/dashboard.service';
import { HandlerService } from '../services/handler.service';
import * as Chart from 'chart.js'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  is_admin: boolean;
  is_dist: boolean;
  is_factory: boolean;
  is_sole: boolean;
  loading: boolean = false;

  admin_reports: any;
  active_distribution_data: any;
  active_distribution_options: any;

  regional_shop_data: any;
  regional_shop_options: any;
  top_distribution: any;
  active_route: any;
  top_pricing_data: any;
  top_pricing_options: any;
  active_distribution_canvas: any;
  active_distribution_ctx: any;
  regional_shop_canvas: any;
  regional_shop_ctx: any;
  top_pricing_canvas: any;
  top_pricing_ctx: any;
  sales_linear_canvas: any;
  sales_linear_ctx: any
  sales_cum_canvas: any;
  sales_cum_ctx: any


  constructor(private as: AuthService, private ms: MessageService, private ds: DashboardService, private hs: HandlerService) { }

  ngOnInit() {

    if (this.as.is_admin) {
      this.is_admin = true;
      this.loading = true;
      this.ds.getAdminDashboardReports().subscribe(reports => {
        this.admin_reports = reports;
        this.loading = false;
        this.top_distribution = this.admin_reports.top_distribution;
        this.active_route = this.admin_reports.active_route;
        setTimeout(() => {
          this.generateADChart();
          this.generateARSChart();
          this.generateATPChart();
          this.generateALSChart();
          this.generateACSChart();
        }, 500);

      },
        error => {
          this.hs.handleError(error)
        })
    }
  }
  generateADChart() {
    this.active_distribution_data = {
      labels: ['Active Distributions', 'Inactive Distributions'],
      datasets: [
        {
          data: [this.admin_reports.active_distribution['active'], this.admin_reports.active_distribution['inactive']],
          backgroundColor: [
            '#33A532',
            '#FF6347'
          ],
        }],
    }
    this.active_distribution_options = {
      display: true,
      legend: {
        position: 'right',
        labels: {
          boxWidth: 10
        }
      },
      elements: {
        arc: {
          borderWidth: 0
        }
      },
    }
  }
  generateARSChart() {
    let labels = [];
    let data = [];
    this.admin_reports.regional_shop.regional_shop_details.forEach(x => {
      labels.push(x.region['name'])
      data.push(x.total_rate.toFixed(1));
    })

    this.regional_shop_data = {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: [
            '#33A532',
            '#FFCCCB',
            '#003080',
            '#B8860B',
            '#808000',
            '#556B2F',
            '#006400',
            '#32CD32'
          ],
        }]
    }
    this.regional_shop_options = {
      // responsive: true,
      // maintainAspectRatio: false,
      display: true,
      legend: {
        position: 'right',
        labels: {
          boxWidth: 10
        }
      },
      elements: {
        arc: {
          borderWidth: 0
        }
      }
    }

  }
  generateATPChart() {
    let pricing_labels = [];
    let pricing_data = [];
    this.admin_reports.total_pricing.total_pricing_details.forEach(x => {
      pricing_labels.push(x.pricing['product']['name'] + ' ' + x.pricing['sku']['name'] + ' ' + x.pricing['variant']['name'])
      pricing_data.push(x.rate);
    })
    pricing_labels.push('Others');
    pricing_data.push(this.admin_reports.total_pricing.other_rate);



    this.top_pricing_data = {
      labels: pricing_labels,
      datasets: [
        {
          data: pricing_data,
          backgroundColor: [

            '#4682B4',
            '#5F9EA0',
            '#4169E1',
            '#000080',
            '#8A2BE2',
            '#4B0082',
            '#228B22',
            '#32CD32',
            '#006400',
            '#ADFF2F',
            '#9ACD32',
            '#6B8E23',
            '#808000',
            '#B8860B',
            '#DAA520',
            '#1F45FC'
          ],
        }],
    }
    this.top_pricing_options = {

      responsive: true,
      display: true,
      legend: {
        position: 'right',
        labels: {
          boxWidth: 10,
          fontSize: 9
        }
      },
      elements: {
        arc: {
          borderWidth: 0
        }
      }
    }


  }
  generateALSChart() {
    let sales_labels = [];
    let sales_data = [];
    let dates: any = []
    this.admin_reports.total_sales_linear.forEach(x => {
      dates.push(x['on_created'].split('T')[0]);
      sales_data.push(x.total_carton);
    })
    dates.sort();
    sales_labels = dates;
    // dates.forEach(x=>{
    //   sales_labels.push(x['product']['name'] + ' ' + x.pricing['sku']['name'] + ' ' + x.pricing['variant']['name'])
    // })
    this.sales_linear_canvas = document.getElementById('sales_linear');
    if (this.sales_linear_canvas) {
      this.sales_linear_ctx = this.sales_linear_canvas.getContext('2d');
      let sales_linear = new Chart(this.sales_linear_ctx, {
        type: "line",
        data: {
          labels: sales_labels,
          datasets: [
            {
              data: sales_data,
              label: 'Linear Sales',
              fill: true,
              // backgroundColor: "grey",
              // pointBackgroundColor: "#55bae7",
              // pointBorderColor: "#55bae7",
              // pointHoverBackgroundColor: "#55bae7"

            }],
        },
        options: {
          // scales: {
          //   yAxes: [{
          //     ticks: {
          //       suggestedMin: 50,
          //       suggestedMax: 100
          //     }
          //   }]
          // }
        }
      });
    }
  }
  generateACSChart() {
    let sales_labels = [];
    let sales_data = [];
    let dates: any = []
    this.admin_reports.total_sales_cumulative.forEach(x => {
      dates.push(x['on_created'].split('T')[0]);
      sales_data.push(x.total_carton);
    })
    dates.sort();
    sales_labels = dates;
    // dates.forEach(x=>{
    //   sales_labels.push(x['product']['name'] + ' ' + x.pricing['sku']['name'] + ' ' + x.pricing['variant']['name'])
    // })
    this.sales_cum_canvas = document.getElementById('sales_cum');
    if (this.sales_cum_canvas) {
      this.sales_cum_ctx = this.sales_cum_canvas.getContext('2d');
      let sales_cum = new Chart(this.sales_cum_ctx, {
        type: "line",
        data: {
          labels: sales_labels,
          datasets: [
            {
              data: sales_data,
              label: 'Cumulatives Sales',
              fill: true
            }],
        }
      });
    }
  }
  updateADChart() {
    this.loading = true;
    this.ds.updateAdminDistReport().subscribe(response => {
      if (response == 0) {
        this.ngOnInit();
      }
    },
      error => {
        this.hs.handleError(error)
      })
  }
  updateARSChart() {
    this.loading = true;
    this.ds.updateAdminShopReport().subscribe(response => {
      if (response == 0) {
        this.ngOnInit();
      }
    },
      error => {
        this.hs.handleError(error)
      })
  }
  updateATDChart() {
    this.loading = true;
    this.ds.updateAdminTopDistReport().subscribe(response => {
      if (response == 0) {
        this.ngOnInit();
      }
    },
      error => {
        this.hs.handleError(error)
      })
  }

  updateAARChart() {
    this.loading = true;
    this.ds.updateAdminActiveRouteReport().subscribe(response => {
      if (response == 0) {
        this.ngOnInit();
      }
    },
      error => {
        this.hs.handleError(error)
      })
  }
  updateATPChart() {
    this.loading = true;
    this.ds.updateAdminTopPricingReport().subscribe(response => {
      if (response == 0) {
        this.ngOnInit();
      }
    },
      error => {
        this.hs.handleError(error)
      })
  }
}
