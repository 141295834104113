import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,

  FormControl, FormGroup,

  Validators
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { SelectItem } from "primeng/api";
import { Distribution } from "src/app/distribution/models/distribution.model";
import { HandlerService } from "src/app/services/handler.service";
import { OrderService } from "src/app/services/order.service";
import * as XLSX from "xlsx";

@Component({
  selector: "app-day-close-report-details",
  templateUrl: "./day-close-report-details.component.html",
  styleUrls: ["./day-close-report-details.component.css"]
})
export class DayCloseDetailsReportComponent implements OnInit {
  options = {
    rowHeaders: true,
    columnHeaders: true,
    fixedRowsTop: 0,
    fixedColumnsLeft: 0,
    contextMenu: false,
    manualColumnFreeze: false,
    columnSorting: false
  };

  cols: any[];
  fk_distribution: number;
  distributions_array: Distribution[] = [];
  form: FormGroup;
  start_date: string;
  end_date: string;
  distributions: SelectItem[] = [];
  fk_sole: number;
  is_dist: boolean;
  submitted: boolean = false;
  reports: any[] = [];
  total_orders: number;
  pending_orders: number;
  dispatched_orders: number;
  delivered_orders: number;
  booked_cartons: number;
  received_cartons: number;
  delivered_cartons: number;
  body: any;

  loading: boolean = false;
  first = 0;
  //==========================================================
  content_id: string = "content";

  order_date: any;

  distribution_name: any;
  fileName = "ExcelSheet.xlsx";

  constructor(
    private fb: FormBuilder,
    private orderService: OrderService,
    private handlerService: HandlerService,
    private route: ActivatedRoute
  ) { }

  // detectChanges = (hotInstance, changes, source) => {
  //   console.log(changes);
  // };
  getNextLetter(num): String {
    var code: number = "abcdefghijklmnopqrstuvwxyz".charCodeAt(num);
    return String.fromCharCode(code);
  }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.fk_distribution = params["dist_id"];
      this.order_date = params["order_date"];
      this.distribution_name = params["dist_name"];
      this.start_date = params["start_date"];
      this.end_date = params["end_date"];
      this.body = JSON.parse(params["body"])
    });

    this.fileName = this.handlerService.fileName("dist-sale-report");
    // this.loading = true;
    if (this.fk_distribution && this.order_date) {
      this.onSubmit();
    }

    //FORM VALIDATIONS
    this.form = this.fb.group({
      start_date: new FormControl(null, Validators.required),
      end_date: new FormControl(null, Validators.required)
    });

    this.cols = [
      { field: "product", header: "Product" },
      { field: "sku", header: "SKU" },
      { field: "opening_carton", header: "Opening" },
      { field: "closing_carton", header: "Closing" }
    ];
  }
  //=========================  END ngOnInit() ================================

  distcheck() {
    console.log(this.fk_distribution);
  }

  onSubmit() {
    this.loading = true;
    this.resetValues();
    this.orderService
      .dayCloseReportDetails(this.fk_distribution, this.order_date, this.body)
      .subscribe(
        (data: any[]) => {
          this.reports = data;
          this.reports.forEach(report => {
            report.route_details.forEach(detail => {
              this.total_orders += detail.total_orders;
              this.pending_orders += detail.pending_orders;
              this.dispatched_orders += detail.dispatched_orders;
              this.delivered_orders += detail.delivered_orders;
              this.booked_cartons += detail.booked_cartons;
              this.received_cartons += detail.received_cartons;
              this.delivered_cartons += detail.delivered_cartons;
            })
          })
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.handlerService.handleError(error);
        }
      );
  }

  resetValues() {
    this.reports = [];
    this.total_orders = 0;
    this.pending_orders = 0;
    this.dispatched_orders = 0;
    this.delivered_orders = 0;
    this.booked_cartons = 0;
    this.received_cartons = 0;
    this.delivered_cartons = 0;
  }

  paginate(event) {
    this.first = event.first;
  }

  distSelected() {
    let dist = this.distributions_array.filter(
      x => x.id == this.fk_distribution
    )[0];
    this.distribution_name = dist["name"];
  }

  //=====================================================================================
  //         EXCEL

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
}
