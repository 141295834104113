import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';

import { catchError, switchMap, filter, take, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()

export class TokenInterceptor implements HttpInterceptor {

    inter = 0;
    private refreshTokenInProgress = false;
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private injector: Injector, private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authService = this.injector.get(AuthService);
        let tokenizedRequest = req.clone({
            setHeaders: {
                Authorization: `Bearer ${authService.getToken()}`
            }
        })

        if (!this.authService.getRefreshToken()) {
            return next.handle(tokenizedRequest);
        }
        return next.handle(tokenizedRequest).pipe(catchError(error => {
            console.log('interceptor 35: =========================================')
            console.log(error)

            if (error.error == 'invalid_token') {
                confirm('You were signed out of your account. Please sign in again');
                this.authService.logOutp()
            }
            if (error instanceof HttpErrorResponse && error.status === 401) {
                console.log('401')
                return this.handle401Error(req, next);
            } else {
                console.log(error);
                return throwError(error);
            }
        })
        );
    }

    //ADD TOKEN TO HEADER OF HTTP REQUEST
    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        let authService = this.injector.get(AuthService);
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            return authService.refreshToken()
                .pipe(
                    switchMap((token: any) => {
                        this.isRefreshing = false;
                        this.refreshTokenSubject.next(token.access_token);
                        return next.handle(this.addToken(request, token.access_token));
                    })
                );
        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(access_token => {
                    this.isRefreshing = false;
                    return next.handle(this.addToken(request, access_token));
                })
            );
        }
    }
}