import { Component } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {
  user: any;
  name: string;
  type: string;
  constructor(public app: AppMainComponent, private router: Router, private authService: AuthService) {
    if (!localStorage.getItem('user')) {
      this.authService.logOut();
    }
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user.user['user_type'] == 'root') {
      this.name = this.user.user['username'];
      this.type = 'Admin';
    }
    else if (this.user.user['user_type'] == 'distribution') {
      this.name = this.user.distribution['name'];
      this.type = 'Distribution';
    }
    else if (this.user.user['user_type'] == 'sole') {
      this.name = this.user.sole['name'];
      this.type = 'Sole';
    }
    else if (this.user.user['user_type'] == 'factory') {
      this.name = this.user.factory['name'];
      this.type = 'Factory';
    }
    else if (this.user.user['user_type'] == 'employee') {
      this.name = this.user.user['username'];
      this.type = this.user.employee['designation'];

    }
  }
  onLogout() {
    this.authService.logOut();
    this.router.navigateByUrl('/login');
  }
}
