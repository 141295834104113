import { Routes, RouterModule } from "@angular/router";
import { ModuleWithProviders } from "@angular/core";

import { AppMainComponent } from "./app.main.component";
import { AppNotfoundComponent } from "./pages/app.notfound.component";
import { AppErrorComponent } from "./pages/app.error.component";
import { AppAccessdeniedComponent } from "./pages/app.accessdenied.component";
import { AppLoginComponent } from "./pages/app.login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AppPasswordResetComponent } from "./pages/app.pass-reset.component";

import { AuthGuard } from "./guards/auth.guard";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { DayCloseDetailsReportComponent } from "./orders/reports/day-close-report-details/day-close-report-details.component";

export const routes: Routes = [
  { path: "login", component: AppLoginComponent },
  { path: "reset_password", component: AppPasswordResetComponent },
  {
    path: "",
    component: AppMainComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/dashboard", pathMatch: "full" },
      { path: "dashboard", component: DashboardComponent },

      {
        path: "regional-hierarchy",
        loadChildren:
          "./regionalhierarchy/regionalhierarchy.module#RegionalhierarchyModule",
      },
      {
        path: "product",
        loadChildren: "./product/product.module#ProductModule",
      },
      {
        path: "pricing",
        loadChildren: "./pricing/pricing.module#PricingModule",
      },
      {
        path: "distributory",
        loadChildren: "./distribution/distribution.module#DistributionModule",
      },
      { path: "stock", loadChildren: "./stock/stock.module#StockModule" },
      {
        path: "factory",
        loadChildren: "./factory/factory.module#FactoryModule",
      },
      { path: "sole", loadChildren: "./sole/sole.module#SoleModule" },

      {
        path: "shopcredit",
        loadChildren: "./shopcredit/shopcredit.module#ShopcreditModule",
      },
      { path: "shop", loadChildren: "./shops/shops.module#ShopsModule" },
      // { path: 'stock', loadChildren: './stock/stock.module#StockModule' },
      {
        path: "tradeoffer",
        loadChildren: "./tradeoffer/tradeoffer.module#TradeofferModule",
      },
      {
        path: "orderbooker",
        loadChildren: "./orderbooker/orderbooker.module#OrderbookerModule",
      },
      {
        path: "deliveryman",
        loadChildren: "./deliveryman/deliveryman.module#DeliverymanModule",
      },
      { path: "order", loadChildren: "./orders/orders.module#OrdersModule" },
      {
        path: "employee",
        loadChildren: "./employee/employee.module#EmployeeModule",
      },

      // {
      //   path: "orderbooker-route",
      //   loadChildren:
      //     "./orderbooker-route/orderbooker-route.module#OrderbookerRouteModule",
      // },
      // {
      //   path: "deliveryman-route",
      //   loadChildren:
      //     "./deliveryman-route/deliveryman-route.module#DeliverymanRouteModule"
      // }
    ],
  },

  {
    path:
      "day-close-details/:order_date/:dist_id/:dist_name/:start_date/:end_date/:body",
    component: DayCloseDetailsReportComponent,
  },
  {
    path: "god-mode",
    loadChildren: "./god-mode/god-mode.module#GodModeModule",
  },
  { path: "error", component: AppErrorComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "accessdenied", component: AppAccessdeniedComponent },
  { path: "404", component: AppNotfoundComponent },
  { path: "**", redirectTo: "/404" },
];
export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {
  scrollPositionRestoration: "enabled",
});
