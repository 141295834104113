import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { HandlerService } from "./handler.service";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  private baseUrl: string;
  token: string;

  constructor(private http: HttpClient, private handleService: HandlerService) {
    this.baseUrl = this.handleService.getBaseUrl();
  }

  createPrimaryOrder(order, dist_id: number) {
    // https://api.dev.secret.tasteland.com.pk/api/primary_order/create/distribution?distribution_id=1
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url =
      this.baseUrl +
      `primary_order/create/distribution?distribution_id=${dist_id}`;
    return this.http.post(url, JSON.stringify(order), { headers });
  }

  createPrimaryOrderSole(order, sole_id: number) {
    console.log("createPrimaryOrderSole");
    console.log(order);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `primary_order/create/sole?sole_id=${sole_id}`;
    return this.http.post(url, JSON.stringify(order), { headers });
  }

  updatePrimaryOrder(order, order_id: number) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url;
    if (order["status"] == "pending") {
      url = this.baseUrl + `primary_order/pending/${order_id}`;
    } else if (order["status"] == "invoiced") {
      url = this.baseUrl + `primary_order/invoiced/${order_id}`;
    }

    return this.http.put(url, JSON.stringify(order), { headers });
  }

  //dispatch dist po
  dispatchDistPrimaryOrder(order) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `primary_order/dispatch/distribution`;

    return this.http.post(url, JSON.stringify(order), { headers });
  }

  //dispatch SOLE PRIMARY ORDER
  dispatchSolePrimaryOrder(order) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `primary_order/dispatch/sole`;

    return this.http.post(url, JSON.stringify(order), { headers });
  }
  //deliver dist po
  deliverDistPrimaryOrder(order) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `primary_order/deliver/distribution`;

    return this.http.post(url, JSON.stringify(order), { headers });
  }

  getPrimaryOrders(params?: HttpParams) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + "primary_order/all";
    return this.http.get(url, { params, headers });
  }

  getPrimaryOrder(id: number, params?) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + "primary_order/" + id;
    return this.http.get(url, { params, headers });
  }
  dispatchPrimaryOrders(ids: number[]) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `primary_order/dispatch/multiple`;

    return this.http.post(url, JSON.stringify(ids), { headers });
  }
  deliverPrimaryOrders(ids: number[]) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `primary_order/deliver/multiple`;

    return this.http.post(url, JSON.stringify(ids), { headers });
  }

  voidPrimaryOrders(id: number, order) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url =
      this.baseUrl + `primary_order/deliver/undispatch?primary_order_id=${id}`;
    return this.http.post(url, JSON.stringify(order), { headers });
  }

  getPrimaryOrderForInvoice(idz: number[]) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    let url = this.baseUrl + "primary_reports/order_invoice";
    return this.http.post(url, JSON.stringify(idz), { headers });
  }

  // =============================================================
  //BOOKING REPORT
  //==============================================================
  getDistPendingStatement(dist_id, start_date, end_date, include_boxes, body) {
    // https://api.dev.secret.tasteland.com.pk/api/secondary_reports/distribution_pending_statement?distribution_id=1&start_date=21&end_date=322&include_boxes=true
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    let url =
      this.baseUrl +
      `secondary_reports/distribution_pending_statement?distribution_id=${dist_id}&start_date=${start_date}&end_date=${end_date}&include_boxes=${include_boxes}`;
    return this.http.post(url, JSON.stringify(body), { headers });
  }

  //==============================================================
  //      TRANSIT REPORT
  //==============================================================
  getDistDispatchStatement(dist_id, start_date, end_date, include_boxes, body) {
    // https://api.dev.secret.tasteland.com.pk/api/secondary_reports/distribution_dispatch_statement?distribution_id=1&start_date=3&end_date=44&include_boxes=true
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url =
      this.baseUrl +
      `secondary_reports/distribution_dispatch_statement?distribution_id=${dist_id}&start_date=${start_date}&end_date=${end_date}&include_boxes=${include_boxes}`;
    console.log(url);

    return this.http.post(url, JSON.stringify(body), { headers });
  }

  processPrimaryOrders(ids: number[]) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `primary_order/invoice/multiple`;
    return this.http.post(url, JSON.stringify(ids), { headers });
  }
  cancelPrimaryOrders(ids: number[]) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `primary_order/cancel/`;
    return this.http.put(url, JSON.stringify(ids), { headers });
  }

  createSecondaryOrder(order) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + "secondary_order/";
    return this.http.post(url, JSON.stringify(order), { headers });
  }
  getSecondaryOrders(params: HttpParams) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + "secondary_order/all";
    return this.http.get(url, { params, headers });
  }
  updateSecondaryOrder(id, order) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + "secondary_order/" + id;
    return this.http.put(url, JSON.stringify(order), { headers });
  }
  deleteSecondaryOrder(id) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + "secondary_order/" + id;
    return this.http.delete(url, { headers });
  }

  getSecondaryOrderForInvoice(idz: number[]) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    let url = this.baseUrl + "secondary_reports/order_invoice";
    return this.http.post(url, JSON.stringify(idz), { headers });
  }

  getSecondaryOrder(id: number) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + "secondary_order/" + id;
    return this.http.get(url, { headers });
  }

  dispatchSecondaryOrder(order: any) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `secondary_order/dispatch_order`;

    return this.http.post(url, JSON.stringify(order), { headers });
  }
  dispatchSecondaryOrders(ids: number[]) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `secondary_order/dispatch_order/multiple`;

    return this.http.post(url, JSON.stringify(ids), { headers });
  }
  deliverSecondaryOrder(order: any, params: HttpParams) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `secondary_order/deliver_order`;

    return this.http.post(url, JSON.stringify(order), { params, headers });
  }
  deliverSecondaryOrders(ids: number[]) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `secondary_order/deliver/all`;

    return this.http.post(url, JSON.stringify(ids), { headers });
  }
  cancelSecondaryOrders(ids: number[]) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `secondary_order/cancel/`;
    return this.http.put(url, JSON.stringify(ids), { headers });
  }
  shopCreditBalance(id: number, params: HttpParams) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `shop_credit/${id}`;

    return this.http.get(url, { params, headers });
  }

  loadForm(params: HttpParams) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `secondary_reports/loadform`;

    return this.http.post(url, {}, { params, headers });
  }

  deliveryreport(params: HttpParams) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      this.baseUrl + `secondary_reports/delivery_report`,
      null,
      { params: params, headers: headers }
    );
  }

  //====================================================================================
  //            SPECIAL DISPATCH
  //====================================================================================

  generateOrderInvoice(obArray) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `primary_reports/order_invoice`;
    return this.http.post(url, obArray, { headers });
  }

  //====================================================================================
  //            ORDERBOOKER STATEMENT
  //====================================================================================

  getDistReport(
    dist_id,
    start_date,
    end_date,
    include_boxes,
    include_shopList,
    obArray
  ) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    // /?distribution_id=1&start_date=1&end_date=1
    let url =
      this.baseUrl +
      `secondary_reports/distribution_statement?distribution_id=${dist_id}&start_date=${start_date}&end_date=${end_date}&include_boxes=${include_boxes}&include_shopList=${include_shopList}`;
    return this.http.post(url, obArray, { headers });
  }

  getFastEdit(params: HttpParams, body: any) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `secondary_order/fast_edit/get`;
    let x = JSON.stringify(body);
    return this.http.post(url, JSON.stringify(body), { params, headers });
  }

  updateFastEdit(body: any) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `secondary_order/fast_edit/update`;
    return this.http.put(url, JSON.stringify(body), { headers });
  }

  updateFastReturn(body: any) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `secondary_order/deliver/all/with_return`;
    return this.http.post(url, JSON.stringify(body), { headers });
    let x = JSON.stringify(body);
    return this.http.put(url, JSON.stringify(body), { headers });
  }

  //====================================================================================
  //            DAY CLOSE REPORT
  //====================================================================================
  dayCloseReport(dist_id, start_date, end_date, body) {
    // https://api.dev.secret.tasteland.com.pk/api/secondary_reports/day_closing_summary?start_date=1&end_date=3&distribution_id=2

    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url =
      this.baseUrl +
      `secondary_reports/day_closing_summary?start_date=${start_date}&end_date=${end_date}&distribution_id=${dist_id}`;
    return this.http.post(url, body, { headers });
  }
  dayCloseReportDetails(dist_id, order_date, body) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url =
      this.baseUrl +
      `secondary_reports/day_closing_detail?order_date=${order_date}&distribution_id=${dist_id}`;
    return this.http.post(url, body, { headers });
  }

  //====================================================================================
  //            RETURN ORDER
  //====================================================================================
  returnOrder(order) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + "return_order/";
    return this.http.post(url, JSON.stringify(order), { headers });
  }

  getReturnOrders(params: HttpParams) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + "return_order/all";
    return this.http.get(url, { params, headers });
  }

  processOrders(ids: number[]) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `return_order/process_mutiple`;
    return this.http.post(url, ids, { headers });
  }

  cancelOrders(ids: number[]) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `return_order/cancel_multiple`;
    return this.http.put(url, ids, { headers });
  }

  getInvoiceOrders(ids: number[]) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `return_order/invoice`;
    return this.http.post(url, ids, { headers });
  }

  //VISIT
  unproductiveVisit(obj: any) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url = this.baseUrl + `unproductive_visit/`;
    return this.http.post(url, JSON.stringify(obj), { headers });
  }
  getDistDispatchStatementall(dist_id) {
    // https://api.dev.secret.tasteland.com.pk/api/secondary_reports/distribution_dispatch_statement?distribution_id=1&start_date=3&end_date=44&include_boxes=true
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let url =
      this.baseUrl +
      `dailyvisits/get_all_dailyvisits_by_emp_id/${dist_id}`;
    console.log(url);

    return this.http.get(url, { headers });
  }



}
