import { Component } from "@angular/core";
import { AuthService } from "./services/auth.service";
import {
  Router,
  RouterEvent,
  RouteConfigLoadEnd,
  RouteConfigLoadStart
} from "@angular/router";

@Component({
  selector: "app-main",
  templateUrl: "./app.main.component.html",
  styleUrls: ["./app.main.component.css"]
})
export class AppMainComponent {
  menuMode = "static";

  overlayMenuActive: boolean;

  staticMenuDesktopInactive: boolean;

  staticMenuMobileActive: boolean;

  layoutMenuScroller: HTMLDivElement;

  lightMenu = true;

  topbarColor = "layout-topbar-blue";

  menuClick: boolean;

  userMenuClick: boolean;

  notificationMenuClick: boolean;

  rightMenuClick: boolean;

  resetMenu: boolean;

  menuHoverActive: boolean;

  topbarUserMenuActive: boolean;

  topbarNotificationMenuActive: boolean;

  rightPanelMenuActive: boolean;

  inlineUser: boolean;

  isRTL: boolean;
  public isShowingRouteLoadIndicator: boolean;

  counter = 0;
  constructor(private authServcie: AuthService, private router: Router) {
    var asyncLoadCount = 0;

    this.isShowingRouteLoadIndicator = false;

    router.events.subscribe((event: RouterEvent) => {
      if (event instanceof RouteConfigLoadStart) {
        asyncLoadCount++;
      } else if (event instanceof RouteConfigLoadEnd) {
        asyncLoadCount--;
      }

      this.isShowingRouteLoadIndicator = !!asyncLoadCount;
    });
  }

  ngOnInit() {
    this.counter++;
    if (!this.authServcie.isLoggedIn()) {
      this.router.navigateByUrl("/login");
    }
  }

  onLayoutClick() {
    if (!this.userMenuClick) {
      this.topbarUserMenuActive = false;
    }

    if (!this.notificationMenuClick) {
      this.topbarNotificationMenuActive = false;
    }

    if (!this.rightMenuClick) {
      this.rightPanelMenuActive = false;
    }

    if (!this.menuClick) {
      if (this.isHorizontal() || this.isSlim()) {
        this.resetMenu = true;
      }

      if (this.overlayMenuActive || this.staticMenuMobileActive) {
        this.hideOverlayMenu();
      }

      this.menuHoverActive = false;
      this.unblockBodyScroll();
    }

    this.userMenuClick = false;
    this.rightMenuClick = false;
    this.notificationMenuClick = false;
    this.menuClick = false;
  }

  onMenuButtonClick(event) {
    this.menuClick = true;
    this.topbarUserMenuActive = false;
    this.topbarNotificationMenuActive = false;
    this.rightPanelMenuActive = false;

    if (this.isOverlay()) {
      this.overlayMenuActive = !this.overlayMenuActive;
    }

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.staticMenuMobileActive = !this.staticMenuMobileActive;
      if (this.staticMenuMobileActive) {
        this.blockBodyScroll();
      } else {
        this.unblockBodyScroll();
      }
    }

    event.preventDefault();
  }

  onMenuClick($event) {
    this.menuClick = true;
    this.resetMenu = false;
  }

  onTopbarUserMenuButtonClick(event) {
    this.userMenuClick = true;
    this.topbarUserMenuActive = !this.topbarUserMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onTopbarNotificationMenuButtonClick(event) {
    this.notificationMenuClick = true;
    this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;
    this.hideOverlayMenu();
    event.preventDefault();
  }

  onRightMenuClick(event) {
    this.rightMenuClick = true;
    this.rightPanelMenuActive = !this.rightPanelMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onTopbarSubItemClick(event) {
    event.preventDefault();
  }

  isHorizontal() {
    return this.menuMode === "horizontal";
  }

  isSlim() {
    return this.menuMode === "slim";
  }

  isOverlay() {
    return this.menuMode === "overlay";
  }

  isStatic() {
    return this.menuMode === "static";
  }

  isMobile() {
    return window.innerWidth < 1025;
  }

  isDesktop() {
    return window.innerWidth > 896;
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  hideOverlayMenu() {
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

  changeMenuMode(menuMode: string) {
    this.menuMode = menuMode;
    this.staticMenuDesktopInactive = false;
    this.overlayMenuActive = false;
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add("blocked-scroll");
    } else {
      document.body.className += " blocked-scroll";
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll");
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  }
}
