import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(public authService: AuthService) {

  }

  canActivate() {
    this.authService.isAdmin()

    if (this.authService.is_admin) {

      return true;
    } else {

      console.log('not admin in ')
      alert('You are not an admin');
      // this.authService.logOut();
      // this.router.navigate(['/login']);
      return false;
    }
  }

}
