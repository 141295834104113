import { Component, OnInit } from '@angular/core';
import { SelectItem, MessageService } from 'primeng/api';
import { LoginService } from '../services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { HandlerService } from '../services/handler.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  loading: boolean = false;

  options: SelectItem[] = [
    { label: 'Request email through username', value: 'username' },
    { label: 'Request email through email ID', value: 'email' },
  ];
  option: any;
  username;
  email;
  usernameshow: boolean = false;
  emailshow: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private messageService: MessageService,
    private loginService: LoginService,
    private handlerService: HandlerService,
  ) { }

  ngOnInit() {
  }
  optionSelect() {
    console.log(this.option);
    if (this.option == 'username') {
      this.usernameshow = true;
      this.emailshow = false;

    }

    if (this.option == 'email') {
      this.emailshow = true;
      this.usernameshow = false;
    }

  }

  submitForgotPass() {
    let params = new HttpParams();
    if (this.username) {
      params = params.set('username', this.username)
    }
    if (this.email) {
      params = params.set('email', this.email)
    }
    this.loading = true;
    this.loginService.passReset(params).subscribe(
      data => {
        this.loading = false;
        console.log(data)
        this.messageService.add({ severity: 'success', key: 'msg', detail: 'Reset link has been sent to your email' })
        setTimeout(() => {
          this.router.navigateByUrl('/login');
        }, 1500);
      },
      error => {
        this.loading = false;
        console.log(error);
        if (typeof error == 'string') {
          this.messageService.add({ key: 'msg', severity: 'error', summary: 'Error ', detail: error });
        }
        if (error.message) {
          this.messageService.add({ key: 'msg', severity: 'error', summary: 'Error ', detail: error.message });

        }
        if (error.error) {
          console.log("error.error")
          console.log(error.error)
          if (error.error.detail) {
            console.log("error.error.detail")
            console.log(error.error.detail)
            if (!Array.isArray(error.error.detail)) {
              // console.log(error.error)
              this.messageService.add({ key: 'msg', severity: 'error', summary: 'Error ', detail: error.error.detail });
            } else {
              error.error.detail.forEach(er => {
                this.messageService.add({ key: 'msg', severity: 'error', summary: 'Error ', detail: er['msg'] });
              });
            }
          }
        }
        if (Array.isArray(error)) {
          error.forEach(er => {
            this.messageService.add({ key: 'msg', severity: 'error', summary: 'Error ', detail: er['loc'][2] + ': ' + er['msg'] });
          });
        }

      }
    )
  }
}
