import {
  HttpClient, HttpErrorResponse, HttpHeaders
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AccessToken } from "../distribution/models/token.model";
import { Login } from "../pages/app.login.component";
import { PasswordReset } from "../pages/app.pass-reset.component";
import { HandlerService } from "./handler.service";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  user: any;
  name: string;
  type: string;
  is_dist: boolean = false;

  dist_id: number;
  sole_id: number;
  factory_id: number;
  employee_id: number;

  is_sole: boolean = false;
  is_factory: boolean = false;
  is_admin: boolean = false;
  is_employee: boolean = false;

  refreshTokenInterval: any;

  private readonly TOKEN_EXPIRY_TIME = "expires_in";
  private readonly REFRESH_TOKEN = "refresh_token";
  private readonly TOKEN = "access_token";
  private readonly USER = "user";

  private baseUrl: string;
  token: string;
  emp_designation: any;

  constructor(
    private http: HttpClient,
    private handlerservice: HandlerService,
    private router: Router,
    public jwtHelper: JwtHelperService
  ) {
    this.isAdmin();
    this.baseUrl = handlerservice.getBaseUrl();
  }
  getUser() {
    this.user = JSON.parse(localStorage.getItem("user"));
    return this.user;
  }
  userLogin(user: Login) {
    let finalURL = this.baseUrl + `login/token`;
    const searchParams = Object.keys(user)
      .map(key => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(user[key]);
      })
      .join("&");

    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    return this.http.post(finalURL, searchParams, { headers });
  }

  setUser(response) {
    localStorage.setItem(this.USER, JSON.stringify(response));
  }

  resetTokens() {
    localStorage.clear();
    clearInterval(this.refreshTokenInterval);
  }

  setTokens(response) {
    //save access token in local storage
    localStorage.setItem(this.TOKEN, response[this.TOKEN]);

    //save token expiry time
    let token_expires_in_seconds = "" + response.expires_in * 60;
    localStorage.setItem(
      this.TOKEN_EXPIRY_TIME,
      String(token_expires_in_seconds)
    );
    let token_expires_in_miliseconds = +token_expires_in_seconds * 60;
    this.refreshTokenInterval = setInterval(() => {
      this.refreshToken();
    }, +token_expires_in_miliseconds - 5000);

    // save refresh token
    localStorage.setItem(this.REFRESH_TOKEN, response.refresh_token);
    this.token = response[this.TOKEN];
  }

  PasswordReset(passwords: PasswordReset) {
    let finalURL =
      this.baseUrl +
      `login/password_reset_token?reset_token=${passwords["reset_token"]}&password_in=${passwords["password"]}`;
    const searchParams = Object.keys(passwords)
      .map(key => {
        return (
          encodeURIComponent(key) + "=" + encodeURIComponent(passwords[key])
        );
      })
      .join("&");

    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    return this.http.post(finalURL, searchParams, { headers });
  }
  // CHECK IF ALREADY LOGGED IN
  isLoggedIn(): boolean {
    return !!this.getToken();
  }
  getUserEmployee() {
    if (this.is_employee) {
      this.user = JSON.parse(localStorage.getItem("user"));
      return this.user["employee"];
    }
  }
  isDesignatedEmployee(type: string) {
    if (this.is_employee) {
      this.user = JSON.parse(localStorage.getItem("user"));
      if (this.user.employee.designation == type) {
        return true;
      } else {
        return false;
      }
      // creturn this.user['employee'];
    }
  }

  isAdmin(): boolean {
    let localuser = localStorage.getItem("user");
    if (localuser != 'undefined') {
      this.user = JSON.parse(localuser);
      if (this.user) {
        if (this.user.user["user_type"] == "root") {
          this.name = this.user.user["username"];
          this.type = "Admin";
          this.is_admin = true;
          this.is_sole = false;
          this.is_factory = false;
          this.is_dist = false;
          this.is_employee = false;
          return;
        } else if (this.user.user["user_type"] == "distribution") {
          this.name = this.user.distribution["name"];
          this.dist_id = this.user.distribution["id"];
          this.type = "Distribution";
          this.is_dist = true;
          this.is_sole = false;
          this.is_factory = false;
          this.is_admin = false;
          this.is_employee = false;
          return;
        } else if (this.user.user["user_type"] == "sole") {
          this.name = this.user.sole["name"];
          this.sole_id = this.user.sole["id"];
          this.type = "Sole";
          this.is_sole = true;
          this.is_factory = false;
          this.is_admin = false;
          this.is_dist = false;
          this.is_employee = false;
          return;
        } else if (this.user.user["user_type"] == "factory") {
          this.name = this.user.factory["name"];
          this.factory_id = this.user.factory["id"];
          this.type = "Factory";
          this.is_factory = true;
          this.is_admin = false;
          this.is_sole = false;
          this.is_dist = false;
          this.is_employee = false;
          return;
        } else if (this.user.user["user_type"] == "employee") {
          this.name = this.user.employee["name"];
          this.employee_id = this.user.employee["id"];
          this.type = "Employee";
          this.emp_designation = this.user.employee["designation"];
          this.is_factory = false;
          this.is_employee = true;
          this.is_admin = false;
          this.is_sole = false;
          this.is_dist = false;
          return;
        }
      }
    } else {
      this.logOut()
    }
  }

  //CHECK IF TOKEN IS VALID
  public isAuthenticated(): boolean {
    const token = this.getToken();
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

  refreshToken() {
    let refresh_token = { refresh_token: this.getRefreshToken() };
    let finalURL = this.baseUrl + `login/renew_token`;
    const searchParams = Object.keys(refresh_token)
      .map(key => {
        return (
          encodeURIComponent(key) + "=" + encodeURIComponent(refresh_token[key])
        );
      })
      .join("&");

    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    headers = headers.set("accept", "application/json");
    headers = headers.set("refresh_token", this.getRefreshToken());
    // console.log('inside refreshTOken()')
    return this.http
      .post(finalURL, searchParams, { headers })
      .pipe(
        tap(
          (tokens: AccessToken) => {
            console.log(tokens);
            this.storeNewToken(tokens.access_token);
          },
          (error: any) => {
            console.log(error);
            if (error instanceof HttpErrorResponse) {
              console.log(error);
            }
          }
        )
      )
      .pipe(
        catchError(error => {
          console.log(error);
          let errorMessage = "An error occurred!";
          if (!error.error || !error.error.detail) {
            return throwError(errorMessage);
          }
          errorMessage = error.error.detail;
          this.router.navigate(["/login"]);
          return throwError(errorMessage);
        })
      );
  }

  logOut() {
    window.localStorage.removeItem(this.TOKEN);
    window.localStorage.removeItem(this.REFRESH_TOKEN);
    window.localStorage.clear();
    this.router.navigate(["/login"]);
    return;
  }

  logOutp() {
    window.localStorage.removeItem(this.TOKEN);
    window.localStorage.removeItem(this.REFRESH_TOKEN);
    window.localStorage.clear();
    this.router.navigate(["/login"]);
    return;
  }

  getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  //RETURN access_token stored after login
  getToken() {
    return localStorage.getItem(this.TOKEN);
  }

  storeNewToken(token: string) {
    localStorage.setItem(this.TOKEN, token);
  }
}
