import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMainComponent } from 'src/app/app.main.component';
import { AppMenuComponent, AppSubMenuComponent } from 'src/app/app.menu.component';
import { AppRightMenuComponent } from 'src/app/app.right-menu.component';
import { AppTopBarComponent } from 'src/app/app.topbar.component';
import { AppFooterComponent } from 'src/app/app.footer.component';
import { SharedModule } from '../shared.module';
import { SpinnerComponent } from '../spinner/spinner.component';

@NgModule({
  declarations: [
    AppMainComponent,
    AppMenuComponent,
    AppRightMenuComponent,
    AppSubMenuComponent,
    AppTopBarComponent,
    AppFooterComponent,
    SpinnerComponent,

  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    AppMainComponent,
    AppMenuComponent,
    AppRightMenuComponent,
    AppSubMenuComponent,
    AppTopBarComponent,
    AppFooterComponent,
    SpinnerComponent,

  ]
})
export class FeatureModule { }
