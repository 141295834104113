import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DataViewModule } from 'primeng/dataview';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { AppTopBarComponent2 } from '../app.topbar2.component';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { RegionalhomeComponent } from '../regionalhierarchy/regionalhome/regionalhome.component';

import {
  CalendarModule,
  CheckboxModule,
  DialogModule,
  DropdownModule,
  InputMaskModule,
  InputTextareaModule,
  MessageModule,
  MessagesModule,
  PasswordModule,
  SelectButtonModule,
  SpinnerModule,
  TabMenuModule,
  ToggleButtonModule,
  SlideMenuModule,
  ConfirmDialogModule,
  AccordionModule,
  AutoCompleteModule,
  BreadcrumbModule,
  CarouselModule,
  ChartModule,
  ChipsModule,
  CodeHighlighterModule,
  ColorPickerModule,
  ContextMenuModule,
  EditorModule,
  FieldsetModule,
  FileUploadModule,
  GalleriaModule,
  GrowlModule,
  InplaceModule,
  InputSwitchModule,
  InputTextModule,
  LightboxModule,
  ListboxModule,
  MegaMenuModule,
  MenuModule,
  MenubarModule,
  OrderListModule,
  OrganizationChartModule,
  OverlayPanelModule,
  PaginatorModule,
  PanelMenuModule,
  PickListModule,
  ProgressBarModule,
  RadioButtonModule,
  RatingModule,
  ScrollPanelModule,
  SplitButtonModule,
  StepsModule,
  TerminalModule,
  TieredMenuModule,
  ToolbarModule,
  TooltipModule,
  TreeModule,
  TreeTableModule,
} from 'primeng/primeng';


@NgModule({
  declarations: [AppTopBarComponent2,
    RegionalhomeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    ButtonModule,
    DataViewModule,
    TableModule,
    DropdownModule,
    SpinnerModule,
    MessageModule,
    MessagesModule,
    CheckboxModule,
    InputTextareaModule,
    CalendarModule,
    InputMaskModule,
    SelectButtonModule,
    ToggleButtonModule,
    PanelModule,
    PasswordModule,
    SliderModule,
    MultiSelectModule,
    ToastModule,
    TabViewModule,
    TabMenuModule,
    ProgressSpinnerModule,
    SlideMenuModule,
    ConfirmDialogModule,

    AccordionModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    ChartModule,
    CheckboxModule,
    ChipsModule,
    CodeHighlighterModule,
    ConfirmDialogModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DropdownModule,
    EditorModule,
    FieldsetModule,
    FileUploadModule,
    FullCalendarModule,
    GalleriaModule,
    GrowlModule,
    InplaceModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    LightboxModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    ScrollPanelModule,
    SelectButtonModule,
    SlideMenuModule,
    SliderModule,
    SpinnerModule,
    SplitButtonModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TerminalModule,
    TieredMenuModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    VirtualScrollerModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    ButtonModule,
    DataViewModule,
    TableModule,
    DropdownModule,
    SpinnerModule,
    MessageModule,
    MessagesModule,
    CheckboxModule,
    InputTextareaModule,
    CalendarModule,
    InputMaskModule,
    SelectButtonModule,
    ToggleButtonModule,
    PanelModule,
    PasswordModule,
    SliderModule,
    MultiSelectModule,
    ToastModule,
    TabViewModule,
    TabMenuModule,
    ProgressSpinnerModule,
    DialogModule,
    AppTopBarComponent2,
    SlideMenuModule,
    AccordionModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    ChartModule,
    CheckboxModule,
    ChipsModule,
    CodeHighlighterModule,
    ConfirmDialogModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DropdownModule,
    EditorModule,
    FieldsetModule,
    FileUploadModule,
    FullCalendarModule,
    GalleriaModule,
    GrowlModule,
    InplaceModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    LightboxModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    ScrollPanelModule,
    SelectButtonModule,
    SlideMenuModule,
    SliderModule,
    SpinnerModule,
    SplitButtonModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TerminalModule,
    TieredMenuModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    VirtualScrollerModule,
  ]
})
export class SharedModule { }
