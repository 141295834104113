import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable({
  providedIn: "root",
})
export class HandlerService {
  private baseUrl: string;

  constructor(private ms: MessageService) {
    //this.baseUrl = `https://api.dev.secret.tasteland.com.pk/api/`;

    //this.baseUrl = `https://api.dev.secret.tasteland.com.pk/api/`;
  // 
    this.baseUrl = `https://backend.tasteland.com.pk/api/`;



  }
  fileName(name) {
    var today = new Date();
    var date =
      today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDay();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + " " + time;
    var fileName = name + dateTime + ".xlsx";
    return fileName;
  }
  getBaseUrl() {
    return this.baseUrl;
  }
  handleError(error, page?: string) {
    console.log(error);
    if (typeof error == "string") {
      this.ms.add({
        key: "msg",
        severity: "error",
        summary: "Error " + page,
        detail: error,
      });
    }
    if (error.message) {
      this.ms.add({
        key: "msg",
        severity: "error",
        summary: "Error " + page,
        detail: error.message,
      });
    }
    if (error.error) {
      console.log("error.error");
      console.log(error.error);
      if (error.error.detail) {
        console.log("error.error.detail");
        console.log(error.error.detail);
        if (!Array.isArray(error.error.detail)) {
          // console.log(error.error)
          this.ms.add({
            key: "msg",
            severity: "error",
            summary: "Error ",
            detail: error.error.detail,
          });
        } else {
          error.error.detail.forEach((er) => {
            this.ms.add({
              key: "msg",
              severity: "error",
              summary: "Error ",
              detail: er["msg"],
            });
          });
        }
      }
    }
    if (Array.isArray(error)) {
      error.forEach((er) => {
        this.ms.add({
          key: "msg",
          severity: "error",
          summary: "Error ",
          detail: er["loc"][2] + ": " + er["msg"],
        });
      });
    }
  }
}
