import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ViewChild
} from "@angular/core";
import {
  trigger,
  state,
  style,
  transition,
  animate
} from "@angular/animations";
import { MenuItem, ScrollPanel } from "primeng/primeng";
import { AppMainComponent } from "./app.main.component";
import { AuthService } from "./services/auth.service";

@Component({
  selector: "app-menu",
  templateUrl: "./app.menu.component.html",
  animations: [
    trigger("inline", [
      state(
        "hiddenAnimated",
        style({
          height: "0px",
          overflow: "hidden"
        })
      ),
      state(
        "visibleAnimated",
        style({
          height: "*"
        })
      ),
      transition(
        "visibleAnimated => hiddenAnimated",
        animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")
      ),
      transition(
        "hiddenAnimated => visibleAnimated",
        animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")
      )
    ])
  ]
})
export class AppMenuComponent implements OnInit, AfterViewInit {
  @Input() reset: boolean;

  model: any[];

  inlineModel: any[];

  inlineUserMenuActive = false;

  @ViewChild("layoutMenuScroller", { static: true })
  layoutMenuScrollerViewChild: ScrollPanel;
  is_dist: boolean = false;
  is_sole: boolean = false;
  is_employee: boolean = false;
  is_factory: boolean = false;
  constructor(public app: AppMainComponent, private authService: AuthService) { }

  ngOnInit() {
    this.authService.isAdmin();
    if (this.authService.is_dist) {
      this.is_dist = true;
    } else if (this.authService.is_sole) {
      this.is_sole = true;
    } else if (this.authService.is_factory) {
      this.is_factory = true;
    } else {
      this.is_dist = false;
      this.is_sole = false;
      this.is_factory = false;
    }
    this.model = [
      { label: "Home", icon: "pi pi-fw pi-home", routerLink: ["/"] },
      {
        label: "Sale",
        icon: "pi pi-fw pi-copy",
        items: [
          {
            label: "Territorial Structure",
            icon: "pi pi-fw pi-clone",
            skipLocationChange: true,
            items: [
              {
                label: "Region",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add Region",
                    icon: "pi pi-fw pi-plus",
                    routerLink: ["/regional-hierarchy/createRegion"]
                  },
                  {
                    label: "View Regions",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/regional-hierarchy/viewRegions"],
                    queryParams: { recent: "true" },
                    routerLinkActiveOptions: "{exact: true}",
                    target: "_blank"
                  }
                ],
                visible: this.isVisibleAdmin()
              },
              {
                label: "Zone",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add Zone",
                    icon: "pi pi-fw pi-plus",
                    routerLink: ["/regional-hierarchy/createZone"],
                    target: "_blank"
                  },
                  {
                    label: "View Zones",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/regional-hierarchy/viewZones"],
                    target: "_blank"
                  }
                ],
                visible: this.isVisibleAdmin()
              },
              {
                label: "Area",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add Area",
                    icon: "pi pi-fw pi-plus",
                    routerLink: ["/regional-hierarchy/createArea"],
                    target: "_blank"
                  },
                  {
                    label: "View Areas",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/regional-hierarchy/viewAreas"],
                    target: "_blank"
                  }
                ],
                visible: this.isVisibleAdmin()
              },
              {
                label: "Territory",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add Territory",
                    icon: "pi pi-fw pi-plus",
                    routerLink: ["/regional-hierarchy/createTerritory"],
                    target: "_blank"
                  },
                  {
                    label: "View Territories",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/regional-hierarchy/viewTerritories"],
                    target: "_blank"
                  }
                ],
                visible: this.isVisibleAdmin()
              },
              {
                label: "City",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add City",
                    icon: "pi pi-fw pi-plus",
                    routerLink: ["/regional-hierarchy/createCity"],
                    target: "_blank"
                  },
                  {
                    label: "View Cities",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/regional-hierarchy/viewCities"],
                    target: "_blank"
                  }
                ],
                visible: this.isVisibleAdmin()
              },
              {
                label: "Town",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add Town",
                    icon: "pi pi-fw pi-plus",
                    routerLink: ["/regional-hierarchy/createTown"],
                    target: "_blank"
                  },
                  {
                    label: "View Towns",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/regional-hierarchy/viewTowns"],
                    target: "_blank"
                  }
                ],
                visible: this.isVisibleAdmin()
              },
              {
                label: "Route",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add Route",
                    icon: "pi pi-fw pi-plus",
                    routerLink: ["/regional-hierarchy/createRoute"],
                    target: "_blank"
                  },
                  {
                    label: "View Routes",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/regional-hierarchy/viewRoutes"],
                    target: "_blank"
                  }
                ],
                visible: this.isVisibleAdmin()
              }
            ],
            visible: this.isVisibleAdmin()
            // visible: this.authService.is_employee ? false : true
          },
          {
            label: "Product Information",
            icon: "pi pi-fw pi-clone",
            target: "_blank",
            skipLocationChange: true,
            items: [
              {
                label: "Product",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add Product",
                    icon: "pi pi-fw pi-plus",
                    routerLink: ["/product/createProduct"],
                    target: "_blank",
                    queryParams: { recent: "true" }
                  },
                  {
                    label: "View Products",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/product/viewProducts"],
                    target: "_blank",
                    queryParams: { recent: "true" }
                  }
                ]
              },
              {
                label: "Variant",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add Variant",
                    icon: "pi pi-fw pi-plus",
                    routerLink: ["/product/createVariant"],
                    target: "_blank"
                  },
                  {
                    label: "View Variants",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/product/viewVariants"],
                    target: "_blank"
                  }
                ]
              },
              {
                label: "SKUs",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add SKU",
                    icon: "pi pi-fw pi-plus",
                    routerLink: ["/product/createSku"],
                    target: "_blank"
                  },
                  {
                    label: "View SKUs",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/product/viewSku"],
                    target: "_blank"
                  }
                ]
              }
            ],
            visible: this.isVisibleAdmin()
          },
          {
            label: "Pricing & Discount",
            icon: "pi pi-fw pi-clone",
            items: [
              {
                label: "Pricing Information",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add Pricing",
                    icon: "pi pi-fw pi-plus",
                    routerLink: ["/pricing/createPricing"],
                    target: "_blank"
                  },
                  {
                    label: "View Pricings",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/pricing/viewPricings"],
                    target: "_blank"
                  }
                ],
                visible: this.isVisibleAdmin()
              },
              {
                label: "Distributor Discount",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add Slab",
                    icon: "pi pi-fw pi-sign-in",
                    routerLink: ["/pricing/createSlab"],
                    target: "_blank",
                    visible: this.isVisibleAdmin()
                  },
                  {
                    label: "View Slabs",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/pricing/viewSlabs"],
                    target: "_blank",
                    visible: this.isVisibleAdminnDistTsoAsmRsm()
                  }
                ],
                visible: this.isVisibleAdminnDistTsoAsmRsm()

              },
              {
                label: "Special Discount",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add Slab",
                    icon: "pi pi-fw pi-sign-in",
                    routerLink: ["/pricing/createSpecialDiscount"],
                    target: "_blank",
                    visible: this.isVisibleAdmin()
                  },
                  {
                    label: "View Slabs",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/pricing/viewSpecialDiscounts"],
                    target: "_blank",
                    visible: this.isVisibleAdminnDistTsoAsmRsm()
                  }
                ],
                visible: this.isVisibleAdminnDistTsoAsmRsm()
              }
            ],
            visible: this.isVisibleAdminnDistTsoAsmRsm()
          },
          {
            label: "Distributary",
            icon: "pi pi-fw pi-clone",
            items: [
              {
                label: "Distribution",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add Distribution",
                    icon: "pi pi-fw pi-plus",
                    routerLink: ["/distributory/create-distributor"]
                  },
                  {
                    label: "View Distributions",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/distributory/distributors"]
                  }
                ],
                visible: this.isVisibleAdmin()
              },
              {
                label: "Trade Offer",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Distribution Trade Offer",
                    icon: "pi pi-fw pi-sign-in",
                    items: [
                      {
                        label: "Add Multiple Trade Offer",
                        icon: "pi pi-fw pi-plus",
                        routerLink: [
                          "/tradeoffer/multiple-distribution-trade-offers"
                        ],

                        visible: this.isVisibleAdmin()
                      },
                      {
                        label: "View Trade Offers",
                        icon: "pi pi-fw pi-window-maximize",
                        routerLink: ["/tradeoffer/distribution-trade-offers"],

                        visible: this.isVisibleAdminDistTsoAsmRsmOB()
                      }
                    ],
                    visible: this.isVisibleAdminDistTsoAsmRsmOB()
                  },
                  {
                    label: "Sole Trade Offer",
                    icon: "pi pi-fw pi-sign-in",
                    items: [
                      {
                        label: "Add Multiple Trade Offer",
                        icon: "pi pi-fw pi-plus",
                        routerLink: ["/tradeoffer/multiple-sole-trade-offers"],

                        visible: this.isVisibleAdmin()
                      },
                      {
                        label: "View Trade Offers",
                        icon: "pi pi-fw pi-window-maximize",
                        routerLink: ["/tradeoffer/sole-trade-offers"],

                        visible: this.isVisibleAdminSole()
                      }
                    ],
                    visible: this.isVisibleAdminSole()
                  }
                ],
                visible: this.isVisibleAdminnDistFactorySoleOBTsoAsmRsm()
              },
              {
                label: "Order-Booker",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add Orderbooker",
                    icon: "pi pi-fw pi-plus",
                    routerLink: ["/orderbooker/create-order-booker"]
                  },
                  {
                    label: "View Orderbookers",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/orderbooker/view-order-bookers"]
                  }
                ],
                visible: this.isVisibleAdminnDistTsoAsmRsm()
              },
              {
                label: "Delivery-Man",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add Deliveryman",
                    icon: "pi pi-fw pi-plus",
                    routerLink: ["/deliveryman/create-delivery-man"],
                    target: "_blank"
                  },
                  {
                    label: "View Deliverymen",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/deliveryman/view-delivery-mans"],
                    target: "_blank"
                  }
                ],
                visible: this.isVisibleAdminnDistTsoAsmRsm()
              }
            ]
          },
          {
            label: "Inventory",
            icon: "pi pi-fw pi-clone",
            items: [
              {
                label: "Transfer Stock",
                icon: "pi pi-fw pi-window-maximize",
                routerLink: ["/stock/transfer-stock"],
                visible: this.isVisibleAdmin()
              },
              {
                label: "Sales & Stock Report",
                icon: "pi pi-fw pi-window-maximize",
                routerLink: ["/stock/stock-report"],
                visible: this.isVisibleAdminnDistFactorySoleTsoAsmRsm()
              },
              {
                label: "Opening Stock",
                icon: "pi pi-fw pi-plus",
                routerLink: ["/stock/opening-stock"],
                visible: this.isVisibleAdmin()
              },
              {
                label: "Add Production",
                icon: "pi pi-fw pi-plus",
                routerLink: ["/stock/add-factory-production"],
                visible: this.isVisibleAdminFactory()
              }
            ],
            visible: this.isVisibleAdminnDistFactorySoleTsoAsmRsm()
          },
          {
            label: "Factory",
            icon: "pi pi-fw pi-clone",
            items: [
              {
                label: "Add Factory",
                icon: "pi pi-fw pi-plus",
                routerLink: ["/factory/createFactory"],
                target: "_blank"
              },
              {
                label: "View Factories",
                icon: "pi pi-fw pi-window-maximize",
                routerLink: ["/factory/viewFactory"],
                target: "_blank"
              }
            ],
            visible: this.isVisibleAdmin()
          },
          {
            label: "Sole",
            icon: "pi pi-fw pi-clone",
            items: [
              {
                label: "Add Sole",
                icon: "pi pi-fw pi-plus",
                routerLink: ["/sole/createSole"],
                target: "_blank"
              },
              {
                label: "View Soles",
                icon: "pi pi-fw pi-window-maximize",
                routerLink: ["/sole/viewSoles"],
                target: "_blank"
              }
            ],
            visible: this.isVisibleAdmin()
          },
          {
            label: "Shop Credit",
            icon: "pi pi-fw pi-clone",
            items: [
              {
                label: "Activate",
                icon: "pi pi-fw pi-plus",
                routerLink: ["/shopcredit/activate"],
                target: "_blank"
              },
              {
                label: "Deactivate",
                icon: "pi pi-fw pi-minus",
                routerLink: ["/shopcredit/deactivate"],
                target: "_blank"
              },
              {
                label: "Pay Credit",
                icon: "pi pi-fw pi-sign-in",
                routerLink: ["/shopcredit/pay-credit"],
                target: "_blank"
              }
            ],
            visible: this.isVisibleAdminnDistTsoAsmRsm()
          },
          {
            label: "Shop Census",
            icon: "pi pi-fw pi-clone",
            items: [
              {
                label: "Shop Type",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add Shop Type",
                    icon: "pi pi-fw pi-plus",
                    routerLink: ["/shop/createShoptype"],
                    target: "_blank"
                  },
                  {
                    label: "View Shop Types",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/shop/viewShoptypes"],
                    target: "_blank"
                  }
                ],
                visible: this.isVisibleAdmin()
              },
              {
                label: "Shop Information",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add Shop",
                    icon: "pi pi-fw pi-plus",
                    routerLink: ["/shop/createShop"],
                    target: "_blank"
                  },
                  {
                    label: "View Shops",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/shop/viewShops"],
                    target: "_blank",

                    visible: this.isVisibleAdminnDistTsoAsmRsm()
                  }
                ],
                visible: this.isVisibleAdminDistTsoAsmRsmOB()
              }
            ],
            visible: this.isVisibleAdminDistTsoAsmRsmOB()
          },
          {
            label: "Order Information",
            icon: "pi pi-fw pi-clone",
            items: [
              {
                label: "Primary Order",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add Order",
                    icon: "pi pi-fw pi-plus",
                    routerLink: ["/order/create-primary-order"],
                    visible: this.isVisibleAdminnDistSoleTsoAsmRsm()
                  },
                  {
                    label: "View Orders",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/order/view-primary-orders"],
                    visible: this.isVisibleAdminnDistFactorySoleTsoAsmRsm()
                  }
                ],
                visible: this.isVisibleAdminnDistFactorySoleTsoAsmRsm()
              },
              {
                label: "Secondary Order",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Add Order",
                    icon: "pi pi-fw pi-plus",
                    routerLink: ["/order/create-secondary-order"],
                    visible: this.isVisibleAdminDistTsoAsmRsmOB()
                  },
                  {
                    label: "View Orders",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/order/view-secondary-orders"],
                    visible: this.isVisibleAdminnDistTsoAsmRsm()
                  },
                  {
                    label: "Fast Edit",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/order/fast-edit"],
                    visible: this.isVisibleAdminnDistTsoAsmRsm()
                  },
                  {
                    label: "Fast Return",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/order/fast-return"],
                    visible: this.isVisibleAdminnDistTsoAsmRsm()
                  }
                ],
                visible: this.isVisibleAdminDistTsoAsmRsmOB()
              },
              {
                label: "Order Delivery",
                icon: "pi pi-fw pi-sign-in",
                items: [
                  {
                    label: "Load Form",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/order/load-form"]
                  },
                  {
                    label: "Delivery Report ",
                    icon: "pi pi-fw pi-window-maximize",
                    routerLink: ["/order/delivery-report"]
                  }
                ],
                visible: this.isVisibleAdminDistTsoAsmRsmOB()
              },
              {
                label: "Shop Stock Return",
                icon: "pi pi-fw pi-window-maximize",
                items: [
                  {
                    label: "Add Order",
                    icon: "pi pi-fw pi-sign-in",
                    routerLink: ["/order/return-order"],
                  },
                  {
                    label: "View Orders",
                    icon: "pi pi-fw pi-sign-in",
                    routerLink: ["/order/view-return-orders"],
                    visible: this.isVisibleAdmin()
                  }
                ],
                visible: this.isVisibleAdminnDistTsoAsmRsm()
              },
              {
                label: "Distribution Stock Return",
                icon: "pi pi-fw pi-window-maximize",
                items: [
                  {
                    label: "Add Order",
                    icon: "pi pi-fw pi-sign-in",
                    routerLink: ["/order/dist-return-order"],
                  },
                  {
                    label: "View Orders",
                    icon: "pi pi-fw pi-sign-in",
                    routerLink: ["/order/view-dist-return-orders"],
                    visible: this.isVisibleAdmin()
                  }
                ],
                visible: this.isVisibleAdminnDistTsoAsmRsm()
              }
            ]
          },
          {
            label: "Reports",
            icon: "pi pi-fw pi-clone",
            items: [
              {
                label: "Dist. Sale Report",
                icon: "pi pi-fw pi-sign-in",
                routerLink: ["/order/dist-sale-report"]
              },
              {
                label: "Shop Credit Report",
                icon: "pi pi-fw pi-sign-in",
                routerLink: ["/order/credit-report"],

                visible: this.isVisibleAdmin()
              },
              {
                label: "Productivity Report",
                icon: "pi pi-fw pi-sign-in",
                routerLink: ["/order/dist-productivity-report"]
              },
              {
                label: "Dist. Booking Report",
                icon: "pi pi-fw pi-sign-in",
                routerLink: ["/order/booking-report"]
              },

              {
                label: "Dist. Transit Report",
                icon: "pi pi-fw pi-sign-in",
                routerLink: ["/order/transit-report"]
              },
              {
                label: "Day Close Report",
                icon: "pi pi-fw pi-sign-in",
                routerLink: ["/order/day-close"]
              },
              {
                label: "Slab Report",
                icon: "pi pi-fw pi-sign-in",
                routerLink: ["/order/dist-slab-report"],
                visible: this.isVisibleAdmin()
              },
              {
                label: "Primary Report",
                icon: "pi pi-fw pi-sign-in",
                routerLink: ["/order/primary-report"],
                visible: this.isVisibleAdmin()
              },

              // {
              //   label: "Daily Sale Summary",
              //   icon: "pi pi-fw pi-sign-in",
              //   routerLink: ["/order/daily-sale-summary"],
              //   visible: this.isVisibleAdmin()
              // },

            ],
            visible: this.isVisibleAdminnDistTsoAsmRsm()
          }
        ]
        //visible: this.authService.is_employee ? false : true
      },
      {
        label: "Employee",
        icon: "pi pi-fw pi-copy",
        items: [
          {
            label: "Add Employee",
            icon: "pi pi-fw pi-plus",
            routerLink: ["/employee/create-employee"],
            target: "_blank",
            visible: this.isVisibleAdmin()
          },
          {
            label: "View Employees",
            icon: "pi pi-fw pi-clone",
            routerLink: ["/employee/view-employees"],
            target: "_blank",
            visible: this.isVisibleAdmin()
          },
          {
            label: "Create Target",
            icon: "pi pi-fw pi-clone",
            routerLink: ["/employee/target"],
            target: "_blank",
            visible: this.isVisibleAdmin()
          },
          {
            label: "View Target"
            ,
            icon: "pi pi-fw pi-clone",
            routerLink: ["/employee/view-target"],
            target: "_blank",
            visible: this.isVisibleAdminnDistTsoAsmRsm()
          },
          {
            label: "Employee Tracking Map",
            icon: "pi pi-fw pi-clone",
            routerLink: ["/employee/Employee-tracking"],
            target: "_blank",
            visible: this.isVisibleAdminnDistTsoAsmRsm()
          },
          {
            label: "Metabase",
            icon: "pi pi-fw pi-clone",
            routerLink: ["/employee/metabase"],
            target: "_blank",
            visible: this.isVisibleAdmin()
          },
          {
            label: "Target Achievement",
            icon: "pi pi-fw pi-clone",
            routerLink: ["/employee/target-achievement"],
            target: "_blank",
            visible: this.isVisibleAdminnDistTsoAsmRsm()
          }
        ],
        visible: this.isVisibleAdminnDistTsoAsmRsm()
      },

      {
        label: "Attendance",
        icon: "pi pi-fw pi-copy",
        items: [
          {
            label: "Add Attendance",
            icon: "pi pi-fw pi-plus",
            routerLink: ["/employee/create-attendance"],
            target: "_blank",
            visible: this.isVisibleAdminTsoAsmRsmOB()
          },
          {
            label: "Attendance Report",
            icon: "pi pi-fw pi-clone",
            routerLink: ["/employee/view-attendances"],
            target: "_blank",
            visible: this.isVisibleAdmin()
          },


          {
            label: "DMR",
            icon: "pi pi-fw pi-clone",
            routerLink: ["/employee/dmr"],
            target: "_blank",
            visible: this.isVisibleAdminTsoAsmRsmOB()
          },
          {
            label: "DMR Report",
            icon: "pi pi-fw pi-clone",
            routerLink: ["/employee/view-dmr"],
            target: "_blank",
            visible: this.isVisibleAdmin()
          }
        ],
        visible: this.isVisibleAdminTsoAsmRsmOB()
      },
      {
        label: "Account Settings",
        icon: "pi pi-fw pi-copy",
        items: [
          {
            label: "Reset Password",
            icon: "pi pi-fw pi-sign-in",
            routerLink: ["/employee/password-change"],
            target: "_blank"
          },
          {
            label: "Password Override",
            icon: "pi pi-fw pi-sign-in",
            routerLink: ["/employee/password-override"],
            target: "_blank",
            visible: this.isVisibleAdminnDistTsoAsmRsm()
          },
          {
            label: "Password Reset Email",
            icon: "pi pi-fw pi-sign-in",
            routerLink: ["/employee/password-reset-email"],
            target: "_blank",
            visible: this.isVisibleAdmin()
          },
          {
            label: "Access Control",
            icon: "pi pi-fw pi-sign-in",
            routerLink: ["/employee/enable-user"],
            target: "_blank",
            visible: this.isVisibleAdmin()
          },
          {
            label: "God Mode",
            icon: "pi pi-fw pi-sign-in",
            routerLink: ["/god-mode"],
            target: "_blank",
            visible: this.isVisibleAdminGod()
          }
        ]
      },
      {
        label: "Mvr Reports",
        icon: "pi pi-fw pi-clone",
        routerLink: ["/viewShops"],
        visible: this.isVisibleAdmin()
      }
    ];
    this.inlineModel = [
      {
        label: "Profile",
        icon: "pi pi-fw pi-user"
      },
      {
        label: "Settings",
        icon: "pi pi-fw pi-cog"
      },
      {
        label: "Messages",
        icon: "pi pi-fw pi-envelope"
      },
      {
        label: "Notifications",
        icon: "pi pi-fw pi-bell"
      }
    ];
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.layoutMenuScrollerViewChild.moveBar();
    }, 100);
  }

  changeTheme(theme: string) {
    const layoutLink: HTMLLinkElement = document.getElementById(
      "layout-css"
    ) as HTMLLinkElement;
    layoutLink.href = "assets/layout/css/layout-" + theme + ".css";
    const themeLink: HTMLLinkElement = document.getElementById(
      "theme-css"
    ) as HTMLLinkElement;
    themeLink.href = "assets/theme/" + "theme-" + theme + ".css";
  }

  changeTopbarColor(topbarColor, logo) {
    this.app.topbarColor = topbarColor;
    const topbarLogoLink: HTMLImageElement = document.getElementById(
      "topbar-logo"
    ) as HTMLImageElement;
    topbarLogoLink.src = "assets/layout/images/" + logo + ".svg";
  }

  onMenuClick(event) {
    if (!this.app.isHorizontal()) {
      setTimeout(() => {
        this.layoutMenuScrollerViewChild.moveBar();
      }, 450);
    }
    this.app.onMenuClick(event);
  }

  isVisibleDist() {
    let temp_show = true;
    if (this.is_dist) {
      temp_show = true;
    } else if (this.is_sole) {
      temp_show = false;
    } else if (this.is_factory) {
      temp_show = false;
    } else if (this.authService.is_employee) {
      temp_show = false;
    } else {
      temp_show = false;
    }
    return temp_show;
  }

  isVisibleSole() {
    let temp_show = true;
    if (this.is_dist) {
      temp_show = false;
    } else if (this.is_sole) {
      temp_show = true;
    } else if (this.is_factory) {
      temp_show = false;
    } else {
      temp_show = false;
    }
    return temp_show;
  }

  isVisibleFactory() {
    let temp_show = true;
    if (this.is_dist) {
      temp_show = false;
    } else if (this.is_sole) {
      temp_show = false;
    } else if (this.is_factory) {
      temp_show = true;
    } else {
      temp_show = false;
    }
    return temp_show;
  }

  isVisibleEmployee() {
    let temp_show = true;
    if (this.is_dist) {
      temp_show = false;
    } else if (this.is_sole) {
      temp_show = false;
    } else if (this.is_factory) {
      temp_show = false;
    } else if (this.is_employee) {
      temp_show = true;
    } else {
      temp_show = false;
    }
    return temp_show;
  }

  isVisibleOrderbooker() {
    let temp_show = true;
    if (this.is_dist) {
      temp_show = false;
    } else if (this.is_sole) {
      temp_show = false;
    } else if (this.is_factory) {
      temp_show = false;
    } else if (this.authService.is_employee) {
      if (this.authService.getUserEmployee()["designation"] == "orderbooker") {
        temp_show = true;
      } else {
        temp_show = false;
      }
    } else {
      temp_show = false;
    }
    return temp_show;
  }

  isVisibleAdmin() {
    let temp_show = true;
    if (this.is_dist) {
      temp_show = false;
    } else if (this.is_sole) {
      temp_show = false;
    } else if (this.is_factory) {
      temp_show = false;
    } else if (this.authService.is_admin) {
      temp_show = true;
    } else if (this.authService.is_employee) {
      temp_show = false;
    } else {
      temp_show = false;
    }
    return temp_show;
  }

  isVisibleAdminGod() {
    let temp_show = true;
    if (this.is_dist) {
      temp_show = false;
    } else if (this.is_sole) {
      temp_show = false;
    } else if (this.is_factory) {
      temp_show = false;
    } else if (this.authService.is_employee) {
      temp_show = false;
    } else if (this.authService.is_admin) {
      temp_show = true;
    } else {
      temp_show = false;
    }
    return temp_show;
  }
  // =====================================================================================================
  //                AZHAN SAID TSO AND RSO HAVE SAME ROUTE AUTHORIZATION ACCESS
  // =====================================================================================================
  isVisibleEmploeeTso() {
    if (this.authService.is_employee) {
      if (
        this.authService.getUserEmployee()["designation"] == "tso" &&
        this.authService.is_employee
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isVisibleEmploeeASM() {
    if (this.authService.is_employee) {
      if (
        this.authService.getUserEmployee()["designation"] == "asm" &&
        this.authService.is_employee
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isVisibleEmploeeRSM() {
    if (this.authService.is_employee) {
      if (
        this.authService.getUserEmployee()["designation"] == "rsm" &&
        this.authService.is_employee
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isVisibleEmploeeNSM() {
    if (this.authService.is_employee) {
      if (
        this.authService.getUserEmployee()["designation"] == "nsm" &&
        this.authService.is_employee
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isVisibleEmploeeZSM() {
    if (this.authService.is_employee) {
      if (
        this.authService.getUserEmployee()["designation"] == "zsm" &&
        this.authService.is_employee
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }





  isVisibleAdminASM() {
    if (this.isVisibleAdmin() || this.isVisibleEmploeeASM()) {
      return true;
    } else {
      return false;
    }
  }
  isVisibleAdminASMRSM() {
    if (this.isVisibleAdmin() || this.isVisibleEmploeeASM() || this.isVisibleEmploeeRSM()) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminEmployee() {
    if (this.isVisibleAdmin() || this.authService.is_employee) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminEmployeeTso() {
    if (
      this.isVisibleAdmin() ||
      this.authService.is_employee ||
      this.isVisibleEmploeeTso()
    ) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminnDistFactoryTso() {
    if (
      this.isVisibleAdmin() ||
      this.isVisibleFactory() ||
      this.isVisibleDist() ||
      this.isVisibleEmploeeTso()
    ) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminTsoAsmRsmOB() {
    if (
      this.isVisibleAdmin() ||
      this.isVisibleEmploeeTso() ||
      this.isVisibleEmploeeASM() ||
      this.isVisibleEmploeeRSM() ||
      this.isVisibleOrderbooker()||
      this.isVisibleEmploeeNSM()||
      this.isVisibleEmploeeZSM()
    ) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminSole() {
    if (this.isVisibleAdmin() || this.isVisibleSole()) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminFactory() {
    if (this.isVisibleAdmin() || this.isVisibleFactory()) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminnDistFactorySoleTsoAsmRsm() {
    if (
      this.isVisibleAdmin() ||
      this.isVisibleDist() ||
      this.isVisibleFactory() ||
      this.isVisibleSole() ||
      this.isVisibleEmploeeTso() ||
      this.isVisibleEmploeeRSM() ||
      this.isVisibleEmploeeASM()||
      this.isVisibleEmploeeNSM()||
      this.isVisibleEmploeeZSM()
    ) {
      return true;
    } else {
      return false;
    }
  }
  isVisibleAdminnDistFactorySoleOBTsoAsmRsm() {
    if (
      this.isVisibleAdmin() ||
      this.isVisibleDist() ||
      this.isVisibleFactory() ||
      this.isVisibleSole() ||
      this.isVisibleEmploeeTso() ||
      this.isVisibleOrderbooker() ||
      this.isVisibleEmploeeRSM() ||
      this.isVisibleEmploeeASM()||
      this.isVisibleEmploeeNSM()||
      this.isVisibleEmploeeZSM()
    ) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminDistTsoAsmRsmOB() {
    if (
      this.isVisibleAdmin() ||
      this.isVisibleDist() ||
      this.isVisibleEmploeeTso() ||
      this.isVisibleEmploeeASM() ||
      this.isVisibleEmploeeRSM() ||
      this.isVisibleOrderbooker()||
      this.isVisibleEmploeeNSM()||
      this.isVisibleEmploeeZSM()
    ) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminnDistTsoAsmRsm() {
    if (
      this.isVisibleAdmin() ||
      this.isVisibleDist() ||
      this.isVisibleEmploeeTso() ||
      this.isVisibleEmploeeRSM() ||
      this.isVisibleEmploeeASM()||
      this.isVisibleEmploeeNSM()||
      this.isVisibleEmploeeZSM()
    ) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminnDistTso() {
    if (
      this.isVisibleAdmin() ||
      this.isVisibleDist() ||
      this.isVisibleEmploeeTso()
    ) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminnDist() {
    if (this.isVisibleAdmin() || this.isVisibleDist()) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminTso() {
    if (this.isVisibleAdmin() || this.isVisibleEmploeeTso()) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminnDistSole() {
    if (this.isVisibleAdmin() || this.isVisibleSole() || this.isVisibleDist()) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminnDistSoleTsoRsm() {
    if (
      this.isVisibleAdmin() ||
      this.isVisibleEmploeeTso() ||
      this.isVisibleEmploeeRSM() ||
      this.isVisibleEmploeeNSM()||
      this.isVisibleEmploeeZSM()||
      this.isVisibleSole() ||
      this.isVisibleDist()
    ) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleSoleAdminnFactory() {
    if (
      this.isVisibleSole() ||
      this.isVisibleAdmin() ||
      this.isVisibleFactory()
    ) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminnDistSoleTsoAsmRsm() {
    if (
      this.isVisibleAdmin() ||
      this.isVisibleDist() ||
      this.isVisibleSole() ||
      this.isVisibleEmploeeTso() ||
      this.isVisibleEmploeeRSM() ||
      this.isVisibleEmploeeASM()
      ||
      this.isVisibleEmploeeNSM()||
      this.isVisibleEmploeeZSM()
    ) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminDistSoleTsoAsmOB() {
    if (
      this.isVisibleAdmin() ||
      this.isVisibleDist() ||
      this.isVisibleSole() ||
      this.isVisibleEmploeeTso() ||
      this.isVisibleEmploeeASM() ||
      this.isVisibleOrderbooker()
    ) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleDistAdminFactoryTso() {
    if (
      this.isVisibleAdmin() ||
      this.isVisibleDist() ||
      this.isVisibleFactory() ||
      this.isVisibleOrderbooker ||
      this.isVisibleEmploeeTso()
    ) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleDistAdminFactory() {
    if (
      this.isVisibleAdmin() ||
      this.isVisibleDist() ||
      this.isVisibleFactory() ||
      this.isVisibleOrderbooker
    ) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminOrderbooker() {
    if (this.isVisibleAdmin() || this.isVisibleOrderbooker()) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminOrderbookerTso() {
    if (
      this.isVisibleAdmin() ||
      this.isVisibleOrderbooker() ||
      this.isVisibleEmploeeTso()
    ) {
      return true;
    } else {
      return false;
    }
  }


  isVisibleSolenFactory() {
    if (this.isVisibleSole() || this.isVisibleFactory()) {
      return true;
    } else {
      return false;
    }
  }

  isVisibleAdminnDistEmployee() {
    if (this.isVisibleAdminnDist() || this.isVisibleAdminEmployee()) {
      return true;
    } else {
      return false;
    }
  }
}

@Component({
  /* tslint:disable:component-selector */
  selector: "[app-submenu]",
  /* tslint:enable:component-selector */
  template: `
    <ng-template
      ngFor
      let-child
      let-i="index"
      [ngForOf]="root ? item : item.items"
    >
      <li
        [ngClass]="{ 'active-menuitem': isActive(i) || child.expanded }"
        [class]="child.badgeStyleClass"
        *ngIf="child.visible === false ? false : true"
      >
        <a
          [href]="child.url || '#'"
          (click)="itemClick($event, child, i)"
          (mouseenter)="onMouseEnter(i)"
          *ngIf="!child.routerLink"
          [ngClass]="child.styleClass"
          [attr.tabindex]="!visible ? '-1' : null"
          [attr.target]="child.target"
        >
          <i [ngClass]="child.icon" class="layout-menuitem-icon"></i>
          <span class="layout-menuitem-text">{{ child.label }}</span>
          <i
            class="pi pi-fw pi-angle-down layout-submenu-toggler"
            *ngIf="child.items"
          ></i>
        </a>
        <a
          (click)="itemClick($event, child, i)"
          (mouseenter)="onMouseEnter(i)"
          *ngIf="child.routerLink"
          [routerLink]="child.routerLink"
          routerLinkActive="active-menuitem-routerlink"
          [fragment]="child.fragment"
          [routerLinkActiveOptions]="{ exact: true }"
          [attr.tabindex]="!visible ? '-1' : null"
          [attr.target]="child.target"
        >
          <i [ngClass]="child.icon" class="layout-menuitem-icon"></i>
          <span class="layout-menuitem-text">{{ child.label }}</span>
          <i
            class="pi pi-fw pi-angle-down layout-submenu-toggler"
            *ngIf="child.items"
          ></i>
        </a>
        <div class="layout-menu-tooltip">
          <div class="layout-menu-tooltip-arrow"></div>
          <div class="layout-menu-tooltip-text">{{ child.label }}</div>
        </div>
        <ul
          app-submenu
          [item]="child"
          *ngIf="child.items"
          [visible]="isActive(i)"
          [reset]="reset"
          [parentActive]="isActive(i)"
          [@children]="
            (app.isSlim() || app.isHorizontal()) && root
              ? isActive(i)
                ? 'visible'
                : 'hidden'
              : isActive(i) || child.expanded
              ? 'visibleAnimated'
              : 'hiddenAnimated'
          "
        ></ul>
      </li>
    </ng-template>
  `,
  animations: [
    trigger("children", [
      state(
        "hiddenAnimated",
        style({
          height: "0px"
        })
      ),
      state(
        "visibleAnimated",
        style({
          height: "*"
        })
      ),
      state(
        "visible",
        style({
          height: "*",
          "z-index": 100
        })
      ),
      state(
        "hidden",
        style({
          height: "0px",
          "z-index": "*"
        })
      ),
      transition(
        "visibleAnimated => hiddenAnimated",
        animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")
      ),
      transition(
        "hiddenAnimated => visibleAnimated",
        animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")
      )
    ])
  ]
})
export class AppSubMenuComponent {
  @Input() item: MenuItem;

  @Input() root: boolean;

  @Input() visible: boolean;

  _parentActive: boolean;

  _reset: boolean;

  activeIndex: number;

  constructor(public app: AppMainComponent, public appMenu: AppMenuComponent) { }

  itemClick(event: Event, item: MenuItem, index: number) {
    if (this.root) {
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }

    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    // activate current item and deactivate active sibling if any
    this.activeIndex = this.activeIndex === index ? null : index;

    // execute command
    if (item.command) {
      item.command({ originalEvent: event, item });
    }

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      setTimeout(() => {
        this.appMenu.layoutMenuScrollerViewChild.moveBar();
      }, 450);
      event.preventDefault();
    }

    // hide menu
    if (!item.items) {
      if (this.app.isHorizontal() || this.app.isSlim()) {
        this.app.resetMenu = true;
      } else {
        this.app.resetMenu = false;
      }

      this.app.overlayMenuActive = false;
      this.app.staticMenuMobileActive = false;
      this.app.menuHoverActive = !this.app.menuHoverActive;
      this.app.unblockBodyScroll();
    }
  }

  onMouseEnter(index: number) {
    if (
      this.root &&
      this.app.menuHoverActive &&
      (this.app.isHorizontal() || this.app.isSlim()) &&
      !this.app.isMobile() &&
      !this.app.isTablet()
    ) {
      this.activeIndex = index;
    }
  }

  isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  @Input() get reset(): boolean {
    return this._reset;
  }

  set reset(val: boolean) {
    this._reset = val;
    if (this._reset && (this.app.isHorizontal() || this.app.isSlim())) {
      this.activeIndex = null;
    }
  }

  @Input() get parentActive(): boolean {
    return this._parentActive;
  }
  set parentActive(val: boolean) {
    this._parentActive = val;
    if (!this._parentActive) {
      this.activeIndex = null;
    }
  }
}
