import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Factory } from '../distribution/models/factory.model';
import { HandlerService } from './handler.service';


@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    private baseUrl: string;
    constructor(
        private http: HttpClient,
        private handleService: HandlerService) {
        this.baseUrl = this.handleService.getBaseUrl();
    }

    getAdminDashboardReports() {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        //headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
        return this.http.get(this.baseUrl + `dash_reports/admin_dash_report`, { headers })

    }
    updateAdminDistReport() {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        //headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
        return this.http.post(this.baseUrl + `dash_reports/generate_active_distribution`, { headers })

    }
    updateAdminShopReport() {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        //headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
        return this.http.post(this.baseUrl + `dash_reports/generate_regional_shop`, { headers })

    }
    updateAdminTopDistReport() {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        //headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
        return this.http.post(this.baseUrl + `dash_reports/generate_top_distributions`, { headers })

    }
    updateAdminActiveRouteReport() {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        //headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
        return this.http.post(this.baseUrl + `dash_reports/generate_active_route`, { headers })

    }
    updateAdminTopPricingReport() {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        //headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
        return this.http.post(this.baseUrl + `dash_reports/generate_total_pricing`, { headers })
    }
}
