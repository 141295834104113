import { Injectable } from '@angular/core';
import { Login } from 'src/app/pages/app.login.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HandlerService } from './handler.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private baseUrl;
  token: string;
  constructor(
    private http: HttpClient,
    private handleService: HandlerService
  ) {
    this.baseUrl = this.handleService.getBaseUrl();
  }

  userLogin(userLogin: Login) {
    let finalURL = this.baseUrl + `login/token`;
    const searchParams = Object.keys(userLogin).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(userLogin[key]);
    }).join('&');

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(finalURL, searchParams, { headers })
  }

  passReset(searchParams) {
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    // console.log(searchParams)
    let finalURL;
    if (searchParams.get('username')) {

      finalURL = this.baseUrl + `login/send_reset_email?username=${searchParams.get('username')}`;
    } else {
      finalURL = this.baseUrl + `login/send_reset_email?email=${searchParams.get('email')}`;

    }
    console.log(finalURL)
    return this.http.post(finalURL, { searchParams, headers })
  }

  setTokens(response) {
    // console.log(response['token']);
    localStorage.setItem('token', response['access_token']);
    let token_expires_in_seconds = "" + response['token_expires_in'] * 60;
    localStorage.setItem('token_expires_in', String(token_expires_in_seconds));
    localStorage.setItem('refresh_token', response['refresh_token']);
    this.token = response['access_token'];
  }
  getToken() {
    this.token = localStorage.getItem('token');
    return this.token;
  }
  refreshToken() {
    return localStorage.getItem('refresh_token');
  }
}
