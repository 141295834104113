import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Message, SelectItem } from 'primeng/primeng';
import { AuthService } from '../services/auth.service';
import { HandlerService } from '../services/handler.service';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
  styleUrls: ['./app.login.component.css'],
  providers: [MessageService],
})
export class AppLoginComponent {

  msgs: Message[] = [];
  loading: boolean = false;
  errorMessage: string;
  showDialog: boolean = false;
  options: SelectItem[] = [
    { label: 'Request email through username', value: 'username' },
    { label: 'Request email through email ID', value: 'email' },
  ];
  option: any;
  username;
  email;
  usernameshow: boolean = false;
  emailshow: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private messageService: MessageService,
    private loginService: LoginService,
    private handlerService: HandlerService,
  ) {
    const conn = (navigator as any).connection;
    if (conn) {
      if (conn.saveData) {
        // do something
      }
      const connectionlist = ["slow-2g", "2g", "3g", "4g"];
      const effectiveType = conn.effectiveType;
      console.log("You are on a " + effectiveType + " connection");
    }
  }

  forgotPassword() {
    this.showDialog = true;
  }
  optionSelect() {
    console.log(this.option);
    if (this.option == 'username') {
      this.usernameshow = true;
      this.emailshow = false;

    }

    if (this.option == 'email') {
      this.emailshow = true;
      this.usernameshow = false;
    }

  }

  submitLogin(form) {
    this.loading = true;
    if (form.username && form.password) {
      let user = new Login(form.username, form.password);
      this.authService.resetTokens();
      this.authService.userLogin(user).subscribe(
        response => {
          if (response['user'].disabled) {
            alert('Your account has been deactivated. Kindly contact the administrator');
            this.authService.logOut();
            this.loading = false;
            return;
          } else {
            this.authService.setTokens(response);
            this.authService.setUser(response);
            this.loading = false;
            let return_url = "dashboard";
            let return_temp: string = null;
            this.route.queryParams.subscribe(params => {
              return_temp = params['returnUrl'];
            });
            if (return_temp) {
              return_url = return_temp;
            }
            this.router.navigateByUrl("/" + return_url);
          }
        },
        errorResponse => {
          if (errorResponse.error) {
            this.errorMessage = errorResponse.error.detail;
            this.addErrorMessage(errorResponse.error.detail);
          }
          this.loading = false;
        })
    } else {
      this.loading = false;
      this.messageService.add({ severity: 'error', key: 'msg', detail: 'Please Fill all the fields' })
    }

  }

  addErrorMessage(message: string) {
    this.loading = false;
    this.messageService.add({
      key: 'tst',
      severity: 'error',
      summary: 'Login Error', detail: message
    });
  }
}

export class Login {
  username: string;
  password: string;
  constructor(username: string, password: string) {
    this.username = username;
    this.password = password;
  }
}