import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Message } from 'primeng/primeng';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-pass-reset',
  templateUrl: './app.pass-reset.component.html',
  styleUrls: ['./app.pass-reset.component.css'],
  providers: [MessageService],
})
export class AppPasswordResetComponent implements OnInit {

  msgs: Message[] = [];
  loading: boolean = false;
  errorMessage: string;
  reset_token: string = "";

  constructor(private router: Router,
    private PasswordResetService: AuthService,
    private messageService: MessageService,
    private route: ActivatedRoute,
  ) {
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      // console.log(params)
      this.reset_token = params['token'];
      // console.log(this.reset_token);
    });
  }
  submitReset(form) {

    // console.log(form)
    // console.log(this.reset_token)
    if (form.password && form.password.match("(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}") == null) {
      this.messageService.add({ key: 'msg', severity: 'error', summary: 'Invalid Password', detail: "Password should contain at least a number, lowercase and uppercase alphabet and minimum 6 in length" });
      return;
    }
    if (form.password && form.reset_password) {
      if (form.password != form.reset_password) {
        this.messageService.add({ key: 'msg', severity: 'error', summary: 'Passwords Mismatch', detail: "The passwords don't match plase try again." });
        return;
      }
      this.loading = true;
      let passwords = new PasswordReset(form.password, this.reset_token);

      this.PasswordResetService.PasswordReset(passwords).subscribe(
        response => {
          this.loading = false;
          console.log(response);
          if (response) {
            this.messageService.add({ key: 'msg', severity: 'success', summary: 'Success ', detail: "Your password successfully reset!" });
            setTimeout(() => {

              this.router.navigateByUrl('login');
            }, 2000);
          }
        },
        error => {
          this.loading = false;
          if (error.error) {
            console.log(error);
            console.log(error.error.detail);
            this.errorMessage = error.error.detail;
            this.addErrorMessage(error.error.detail);
          }
          if (typeof error == 'string') {
            this.messageService.add({ key: 'msg', severity: 'error', summary: 'Error ', detail: error });
          }
          console.log(error.error.detail)
          if (error.error.detail) {
            if (!Array.isArray(error.error.detail)) {
              this.messageService.add({ key: 'msg', severity: 'error', summary: 'Error ', detail: error.error.detail });
            } else {
              error.error.detail.forEach(er => {
                this.messageService.add({ key: 'msg', severity: 'error', summary: 'Error ', detail: er['msg'] });
              });
            }
          }
        })
    }
    else {
      this.messageService.add({ severity: 'error', key: 'msg', detail: 'Please Fill all the fields' })
    }
  }

  addErrorMessage(message: string) {
    this.loading = false;
    this.messageService.add({
      key: 'tst',
      severity: 'error',
      summary: 'Password Reset Error', detail: message
    });
  }
}

export class PasswordReset {
  password: string;
  reset_token: string;
  constructor(password: string, reset_token: string) {
    this.password = password;
    this.reset_token = reset_token;
  }
}